header{
    height:100vh;
    padding-top: 7rem;
    overflow: hidden;
}
.header__container{
    text-align: center;
    height:100%;
    position:relative;
}
/* ================ CTA =============*/
.cta{
    margin-top:2.5rem;
    display:flex;
    gap:1.2rem;
    justify-content: center;
}
/* ================ HEADER SOCIALS =============*/
.header__socials{
    display:flex;
    flex-direction: column;
    align-items: center;
    gap:0.8rem;
    position:absolute;
    left:0;
    bottom:3rem;
}
.header__socials::after{
    content:'';
    width:1px;
    height:2rem;
    background: var(--color-primary);
}
/* ================ ME =============*/
.me{
    background: linear-gradient(var(--color-primary),transparent);
    width:250px;
    height:48vh;
    position:justify;
    left:calc(50%-11rem);
    margin-top: 2rem;
    /* border-radius: 12rem 12rem 0 0; */
    border-radius: 6rem;
    overflow:hidden;
    margin-left: auto;
    margin-right: auto;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
    /* background-color: red;  */
}
.me img{
    margin-top: -50px;
}


/* ================ SCROLL DOWN =============*/
.scroll__down{
    position:absolute;
    right:2.3rem;
    bottom:5rem;
    transform:rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
    bottom:7rem;
}


/* =====================MEDIA QUERIES(Medium Devices) =================== */
@media screen and (max-width:1024px){
    header{
        height: 68vh;
    }
    .scroll__down{
        bottom: 28.5rem;
    }
}

/* =====================MEDIA QUERIES(SMALL Devices) =================== */
@media screen and (max-width:600px){
    header{
        height:100vh;
    }
    .header__socials,.scroll__down{
        display:none;
    }
}
